import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#27A95D",
      dark: "#979797",
      light: "#F9F9F9",
    },
    secondary: {
      main: "#1C1C1C",
      // light:"#ECA2F2",
      // dark:"#a8324e",
    },
    // warning:{
    //   main:"#DE0303",
    //   dark:"#883B3B",
    //   light:"#560274"
    // },

    error: {
      main: red.A400,
    },
  },
});

// theme.typography.h1 = {
//   fontSize: "104px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"100%",
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '30px',
//   },
// };
// theme.typography.h2 = {
//   fontSize: "56px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"64px",
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '30px',
//   },
// };
// theme.typography.h3 = {
//   fontSize: "40px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"44px",
//   [theme.breakpoints.down('xs')]: {
//     fontSize: '20px',
//   },
// };
// theme.typography.h4 = {
//   fontSize: "20px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"30px",
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '16px',
//   },
// };
// theme.typography.h5 = {
//   fontSize: "28px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"34px",
//   [theme.breakpoints.down('md')]: {
//     fontSize: '2.4rem',
//   },
// };
// theme.typography.h6 = {
//   fontSize: "28px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"34px",
//   [theme.breakpoints.down('md')]: {
//     fontSize: '2.4rem',
//   },
// };
// theme.typography.body1 = {
//   fontSize: "18px",
//   fontFamily:"Inter-Regular",
//   lineHeight:"32px",
//   [theme.breakpoints.down('md')]: {
//     fontSize: '2.4rem',
//   },
// };
// theme.typography.body2 = {
//   fontSize: "16px",
//   fontFamily:"Gilroy-Medium",
//   lineHeight:"22px",
//   [theme.breakpoints.down('md')]: {
//     fontSize: '2.4rem',
//   },
// };

export default theme;
