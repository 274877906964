import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { logoutRoute } from "../../utils/APIRoutes";
import axios from "axios";
import TurnedInIcon from "@mui/icons-material/TurnedIn";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const id = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_CURRENT_USER)
      );

      if (id === null) {
        navigate("/login");
        return;
      }

      const data = await axios.get(`${logoutRoute}/${id._id}`);
      if (data.status === 200) {
        localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton href="/">
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.06346 7.47449L11.4206 2.16931C11.7763 1.94352 12.2237 1.94352 12.5794 2.16931L20.9366 7.47449C21.5971 7.89382 22 8.64003 22 9.4442V19.6916C22 20.9665 21.0051 22 19.7778 22L4.22222 22C2.99492 22 2 20.9665 2 19.6916L2.00002 9.44419C2.00002 8.64003 2.4029 7.89382 3.06346 7.47449ZM9.77778 12.7664C9.16413 12.7664 8.66667 13.2831 8.66667 13.9206V17.3831C8.66667 18.0206 9.16413 18.5373 9.77778 18.5373H14.2222C14.8359 18.5373 15.3333 18.0206 15.3333 17.3831V13.9206C15.3333 13.2831 14.8359 12.7664 14.2222 12.7664H9.77778Z"
                  fill="white"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.7H23V19.3H1V4.7ZM12 8.35C12.9725 8.35 13.9051 8.73455 14.5927 9.41906C15.2804 10.1036 15.6667 11.032 15.6667 12C15.6667 12.968 15.2804 13.8964 14.5927 14.5809C13.9051 15.2654 12.9725 15.65 12 15.65C11.0275 15.65 10.0949 15.2654 9.40727 14.5809C8.71964 13.8964 8.33333 12.968 8.33333 12C8.33333 11.032 8.71964 10.1036 9.40727 9.41906C10.0949 8.73455 11.0275 8.35 12 8.35ZM5.88889 7.13333C5.88889 7.77869 5.63135 8.39762 5.17293 8.85396C4.71451 9.3103 4.09275 9.56666 3.44444 9.56666V14.4333C4.09275 14.4333 4.71451 14.6897 5.17293 15.146C5.63135 15.6024 5.88889 16.2213 5.88889 16.8667H18.1111C18.1111 16.2213 18.3687 15.6024 18.8271 15.146C19.2855 14.6897 19.9072 14.4333 20.5556 14.4333V9.56666C19.9072 9.56666 19.2855 9.3103 18.8271 8.85396C18.3687 8.39762 18.1111 7.77869 18.1111 7.13333H5.88889Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>Billing History</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8623 21L15.986 10.2H17.8763L22 21H20.1098L19.1553 18.2512H14.7561L13.7714 21H11.8623ZM15.3069 16.6762H18.5554L16.9708 12.225H16.8915L15.3069 16.6762ZM4.71646 18.3L3.43746 17.0287L8.01396 12.48C7.44299 11.8537 6.9434 11.2031 6.51518 10.5281C6.08696 9.85314 5.71377 9.14376 5.3956 8.40001H7.28579C7.56121 8.94126 7.85235 9.42719 8.15921 9.85782C8.46607 10.2884 8.8377 10.745 9.27409 11.2275C9.94315 10.5038 10.4975 9.76438 10.937 9.00937C11.3765 8.25437 11.7441 7.45125 12.0396 6.60001H2V4.80001H8.33843V3H10.1494V4.80001H16.4878V6.60001H13.8506C13.5324 7.66 13.1026 8.69219 12.5612 9.69657C12.0198 10.7009 11.3504 11.645 10.5531 12.5287L12.7187 14.73L12.0396 16.5787L9.24392 13.8L4.71646 18.3Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>Language</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.75 20.0526V19.1052C3.75 15.9659 6.52024 13.421 9.9375 13.421H14.0625C17.4798 13.421 20.25 15.9659 20.25 19.1052V20.0526"
                  fill="#F9F9F9"
                />
                <path
                  d="M3.75 20.0526V19.1052C3.75 15.9659 6.52024 13.421 9.9375 13.421H14.0625C17.4798 13.421 20.25 15.9659 20.25 19.1052V20.0526"
                  stroke="#F9F9F9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M3.75 20.0526V20.188C3.75 20.6364 6.52024 21 9.9375 21H14.0625C17.4798 21 20.25 20.6364 20.25 20.188V20.0526"
                  fill="#F9F9F9"
                />
                <path
                  d="M3.75 20.0526V20.188C3.75 20.6364 6.52024 21 9.9375 21H14.0625C17.4798 21 20.25 20.6364 20.25 20.188V20.0526"
                  stroke="#F9F9F9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M12 10.5789C9.72182 10.5789 7.875 8.88234 7.875 6.78947C7.875 4.6966 9.72182 3 12 3C14.2781 3 16.125 4.6966 16.125 6.78947C16.125 8.88234 14.2781 10.5789 12 10.5789Z"
                  fill="#F9F9F9"
                  stroke="#F9F9F9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>User Profile</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Box border="1px solid #3C3C3C " mt={1}>
        {" "}
      </Box>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 9H11V7H13M13 17H11V11H13M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>About</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.95 18C12.3 18 12.596 17.879 12.838 17.637C13.08 17.395 13.2007 17.0993 13.2 16.75C13.2 16.4 13.0793 16.104 12.838 15.862C12.5967 15.62 12.3007 15.4993 11.95 15.5C11.6 15.5 11.3043 15.621 11.063 15.863C10.8217 16.105 10.7007 16.4007 10.7 16.75C10.7 17.1 10.821 17.396 11.063 17.638C11.305 17.88 11.6007 18.0007 11.95 18ZM11.05 14.15H12.9C12.9 13.6 12.9627 13.1667 13.088 12.85C13.2133 12.5333 13.5673 12.1 14.15 11.55C14.5833 11.1167 14.925 10.704 15.175 10.312C15.425 9.92 15.55 9.44933 15.55 8.9C15.55 7.96667 15.2083 7.25 14.525 6.75C13.8417 6.25 13.0333 6 12.1 6C11.15 6 10.3793 6.25 9.788 6.75C9.19667 7.25 8.784 7.85 8.55 8.55L10.2 9.2C10.2833 8.9 10.471 8.575 10.763 8.225C11.055 7.875 11.5007 7.7 12.1 7.7C12.6333 7.7 13.0333 7.846 13.3 8.138C13.5667 8.43 13.7 8.75067 13.7 9.1C13.7 9.43333 13.6 9.746 13.4 10.038C13.2 10.33 12.95 10.6007 12.65 10.85C11.9167 11.5 11.4667 11.9917 11.3 12.325C11.1333 12.6583 11.05 13.2667 11.05 14.15ZM12 22C10.6167 22 9.31667 21.7377 8.1 21.213C6.88333 20.6883 5.825 19.9757 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>Help</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9 17.39C17.64 16.59 16.89 16 16 16H15V13C15 12.7348 14.8946 12.4804 14.7071 12.2929C14.5196 12.1054 14.2652 12 14 12H8V10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9V7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5V4.59C16.1965 5.07237 17.2582 5.83747 18.0944 6.81983C18.9306 7.8022 19.5163 8.97255 19.8013 10.2307C20.0864 11.4889 20.0623 12.7974 19.7312 14.0442C19.4001 15.291 18.7717 16.4391 17.9 17.39ZM11 19.93C7.05 19.44 4 16.08 4 12C4 11.38 4.08 10.78 4.21 10.21L9 15V16C9 16.5304 9.21071 17.0391 9.58579 17.4142C9.96086 17.7893 10.4696 18 11 18M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ListItemIcon>
            <ListItemText>Website</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <TurnedInIcon
                sx={{
                  fontSize: 24,
                  borderRadius: 0,
                  fill: "white",
                  strokeWidth: 0.1,
                }}
              />
            </ListItemIcon>
            <ListItemText>1.0.0.1</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Box sx={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <Box border="1px solid #3C3C3C " mt={4}>
          {" "}
        </Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.25 15.75V17.625C14.25 18.1223 14.0525 18.5992 13.7008 18.9508C13.3492 19.3025 12.8723 19.5 12.375 19.5H4.875C4.37772 19.5 3.90081 19.3025 3.54917 18.9508C3.19754 18.5992 3 18.1223 3 17.625V6.375C3 5.87772 3.19754 5.40081 3.54917 5.04917C3.90081 4.69754 4.37772 4.5 4.875 4.5H12C13.0355 4.5 14.25 5.33953 14.25 6.375V8.25M17.25 15.75L21 12L17.25 8.25M8.25 12H20.25"
                    stroke="#CE254F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText sx={{ color: " #CE254F !important " }}>
                Logout
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.99997 18.9999H21M2.99997 11.9999H21M2.99997 4.99994H21"
                stroke="#F9F9F9"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              "& .MuiDrawer-paper": {
                backgroundColor: "#1C1C1C",
                color: "white",
              },
              "& .MuiButtonBase-root": {
                fontFamily: "Poppins-Medium",
                fontSize: "15px",
                marginTop: "10px",
                "&:hover": {
                  background: "#27A95D",
                },
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
