import { io } from "socket.io-client";
import { host } from "../utils/APIRoutes";

let socket;

try {
  socket = io(host, {
    reconnectionAttempts: 5, // Number of reconnection attempts before giving up
    reconnectionDelay: 1000, // Delay between reconnection attempts in ms
    secure: true, // Ensure the connection is secure if using HTTPS
  });

  // Handle connection events
  socket.on("connect", (err) => {
    console.log("Connected to server:", err);
  });

  socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });

  socket.on("disconnect", (reason) => {
    console.log("Disconnected from server:", reason);
  });

  // Disconnect the socket when the application is unmounted or before the page unloads
  window.addEventListener("beforeunload", () => {
    if (socket) {
      socket.disconnect();
    }
  });
} catch (err) {
  console.error("Socket initialization error:", err);
}

export default socket;
