import React, { useState } from "react";
import OpenAI from "openai";
import config from "../config";
import styled from "styled-components";
import { FaMicrophone } from "react-icons/fa";

const openaiAPIKey = config.OPENAI_API_KEY;

const VoiceRecorder = ({ onTranscript }) => {
  const [recording, setRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [transcription, setTranscription] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const audioChunksRef = React.useRef([]);

  console.log(config.OPENAI_API_KEY);

  const openai = new OpenAI({
    apiKey: openaiAPIKey,
    dangerouslyAllowBrowser: true,
  });

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data);
          }
        };

        recorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/wav",
          });
          setAudioChunks(audioChunksRef.current);
          audioChunksRef.current = [];
          sendAudioForTranscription(audioBlob);
        };

        recorder.start();
        setRecording(true);
        setMediaRecorder(recorder);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob);

    try {
      const audioFile = new File([audioBlob], "recorded_audio.wav", {
        type: "audio/wav",
      });
      const transcript = await openai.audio.transcriptions.create({
        file: audioFile,
        model: "whisper-1",
        language: "he",
        //prompt: "please please return the hebrew text to be right to left",
      });

      console.log(transcript.text);
      setTranscription(transcript.text);
      onTranscript(transcript.text);
    } catch (error) {
      console.error("Error sending audio for transcription:", error);
    }
  };

  const downloadAudio = () => {
    if (audioChunks.length === 0) {
      return;
    }

    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    const url = URL.createObjectURL(audioBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "recorded_audio.wav";

    // Trigger a click on the invisible "a" element to initiate the download
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  return (
    <Container>
      <StyledButton
        recording={recording}
        onClick={recording ? stopRecording : startRecording}
      >
        <FaMicrophone className="icon" />
        <div className="text">{recording ? "Stop" : "Start"}</div>
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
`;

const StyledButton = styled.button`
  border: 5px solid #d1d1;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: ${(props) => (props.recording ? "#e74c3c" : "#27A95D")};
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .text {
    font-size: 0.95rem;
    color: white;
  }
`;

export default VoiceRecorder;
