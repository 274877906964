import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  List,
  ListItem,
  InputAdornment,
  IconButton,
  Box,
  Button,
  Grid,
} from "@mui/material";
import Navbar from "./Navbar";
import GroupItem from "./GroupsItem";
import { bossId } from "../../utils/consts";
import VoiceRecorder from "../../components/VoiceRecorder";
import axios from "axios";
import { recieveMessageRoute } from "../../utils/APIRoutes";

const ChatBox = ({ socket }) => {
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef(null);
  const [messageId, setMessageId] = useState(1);
  const [inputText, setInputText] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [currentContext, setCurrentContext] = useState(() => {
    return localStorage.getItem(process.env.REACT_APP_CURRENT_CONTEXT) || "";
  });

  // send
  const handleSendMessage = async (msg) => {
    const message = inputText !== "" ? inputText : msg;
    const data = await JSON.parse(
      localStorage.getItem(process.env.REACT_APP_CURRENT_USER)
    );
    socket.emit("client-msg", {
      from: data._id,
      to: bossId,
      message: message,
      contextName: currentContext,
    });

    const msgs = [...messages];
    msgs.push({ messageId, fromBoss: false, message: message });
    setMessages(msgs);
    setMessageId(messageId + 1);
    setInputText("");
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current?.lastElementChild?.scrollIntoView({
        behaviour: "smooth",
      });
    }
  }, [messages]);

  // recieve
  useEffect(() => {
    if (socket) {
      const handleBossMsg = (msg) => {
        if (msg !== null) {
          const msgs = [...messages];
          msgs.push({ messageId, fromBoss: true, message: msg });
          setMessages(msgs);
          setMessageId(messageId + 1);
        }
      };

      socket.on("boss-msg", handleBossMsg);

      return () => {
        socket.off("boss-msg", handleBossMsg);
      };
    }
  }, [socket, messages, messageId]);

  const handleGroupSelection = (name) => {
    setCurrentContext(name);
    localStorage.setItem(process.env.REACT_APP_CURRENT_CONTEXT, name);
  };

  useEffect(() => {
    const recievMsgs = async () => {
      const data = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_CURRENT_USER)
      );
      const response = await axios.post(recieveMessageRoute, {
        contextName: currentContext,
        from: data._id,
        to: data._id,
      });
      setMessages(response.data);
    };
    recievMsgs();
  }, [currentContext]);

  const onTranscript = (text) => {
    setInputText(text);
    handleSendMessage(text);
  };

  return (
    <>
      <Navbar selectedGroup={currentContext} />
      <Box pt={{ xs: 6, sm: 6 }}>
        <Grid container>
          {drawerOpen && (
            <Grid item xs={2} sm={2}>
              <List
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  background: "rgba(39, 169, 93, 0.05)",
                  "& .MuiListItem-root": {
                    paddingTop: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0px",
                  },
                  "& .MuiButton-root": {
                    padding: "3px 0px",
                  },
                }}
              >
                <ListItem>
                  <GroupItem
                    name="inventory"
                    onClick={handleGroupSelection}
                    selectedGroup={currentContext}
                  />
                </ListItem>
                <ListItem>
                  <GroupItem
                    name="money"
                    onClick={handleGroupSelection}
                    selectedGroup={currentContext}
                  />
                </ListItem>
                <ListItem>
                  <GroupItem
                    name="workers"
                    onClick={handleGroupSelection}
                    selectedGroup={currentContext}
                  />
                </ListItem>
                <ListItem>
                  <GroupItem
                    name="alerts"
                    onClick={handleGroupSelection}
                    selectedGroup={currentContext}
                  />
                </ListItem>
                {/* plus button */}
                <ListItem>
                  <Button>
                    <svg
                      width="62"
                      height="62"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1"
                        y="1"
                        width="54"
                        height="54"
                        rx="3"
                        stroke="#3C3C3C"
                        strokeWidth="2"
                      />
                      <path
                        d="M16.6667 28C16.6667 28.6731 17.2299 29.2226 17.8893 29.2226H26.7774V38.1107C26.7774 38.7701 27.3268 39.3333 28 39.3333C28.6731 39.3333 29.2363 38.7701 29.2363 38.1107V29.2226H38.1107C38.7701 29.2226 39.3333 28.6731 39.3333 28C39.3333 27.3269 38.7701 26.7636 38.1107 26.7636H29.2363V17.8893C29.2363 17.2299 28.6731 16.6667 28 16.6667C27.3268 16.6667 26.7774 17.2299 26.7774 17.8893V26.7636H17.8893C17.2299 26.7636 16.6667 27.3269 16.6667 28Z"
                        fill="#3C3C3C"
                      />
                    </svg>
                  </Button>
                </ListItem>
                {/* button for turning to write text */}
                <ListItem sx={{ marginTop: "40px", marginBottom: "30px" }}>
                  <Button onClick={() => setDrawerOpen(false)}>
                    <svg
                      width="62"
                      height="62"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.6133 21.39C40.1333 20.87 40.1333 20.0033 39.6133 19.51L36.4933 16.39C36 15.87 35.1333 15.87 34.6133 16.39L32.16 18.83L37.16 23.83M16 35.0033V40.0033H21L35.7466 25.2433L30.7467 20.2433L16 35.0033Z"
                        fill="#27A95D"
                      />
                    </svg>
                  </Button>
                </ListItem>
              </List>
            </Grid>
          )}

          {/* the chat area */}
          <Grid item xs={drawerOpen ? 10 : 12}>
            <Box sx={{ width: drawerOpen ? "90%" : "100%" }}>
              <Container>
                <Box sx={{ position: "relative" }}>
                  <div
                    style={{
                      height: "calc(75vh - 56px)",
                      overflowY: "auto",
                      marginBottom: 2,
                      scrollbarWidth: "none", // Hide scrollbar for Firefox
                      "-ms-overflow-style": "none", // Hide scrollbar for Internet Explorer/Edge
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar for Chrome, Safari, and Opera
                      },
                    }}
                  >
                    <List>
                      <ul ref={scrollRef}>
                        {messages.map((message) => (
                          <ListItem
                            key={message.messageId}
                            alignItems="flex-start"
                            style={{
                              display: "flex", // Ensure the ListItem is a flex container
                              justifyContent: message.fromBoss
                                ? "flex-end"
                                : "flex-start",
                              marginBottom: 8,
                            }}
                          >
                            <div
                              style={{
                                background: message.fromBoss
                                  ? "#1C1C1C"
                                  : "#27A95D",
                                color: "#FFFFFF",
                                borderRadius: 8,
                                paddingLeft: 16,
                                paddingRight: 16,
                                maxWidth: "70%", // Set a maximum width for the background wrapper
                              }}
                            >
                              {/* Text content */}
                              {message.message}
                            </div>
                          </ListItem>
                        ))}
                      </ul>
                    </List>
                  </div>
                  <Box>
                    {drawerOpen ? (
                      /*<Box textAlign="center">
                        <Box
                          component="img"
                          src={Mircrophoneimg}
                          height="100px"
                          width="100px"
                        />
                    </Box>*/

                      <VoiceRecorder onTranscript={onTranscript} />
                    ) : (
                      <TextField
                        fullWidth
                        placeholder="Type a message"
                        variant="outlined"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton onClick={() => setDrawerOpen(true)}>
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M26.6666 16C26.6666 15.2636 26.0697 14.6667 25.3333 14.6667H10.44L16.9466 8.16C17.4689 7.63771 17.4674 6.79045 16.9433 6.27001C16.4218 5.75216 15.5796 5.75365 15.06 6.27334L6.47617 14.8571C5.84499 15.4883 5.84499 16.5117 6.47617 17.1428L15.0595 25.7262C15.5789 26.2456 16.421 26.2456 16.9404 25.7262C17.4594 25.2072 17.4598 24.3658 16.9412 23.8462L10.44 17.3333H25.3333C26.0697 17.3333 26.6666 16.7364 26.6666 16Z"
                                    fill="#27A95D"
                                    stroke="#27A95D"
                                    strokeWidth="0.5"
                                  />
                                </svg>
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleSendMessage}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.63116 2.12199C2.73762 1.67547 1.74858 2.52801 2.0581 3.47755L4.06518 9.63031C4.12195 9.80413 4.22529 9.95906 4.36396 10.0783C4.50264 10.1974 4.67134 10.2763 4.85171 10.3063L12.782 11.6279C13.1996 11.6979 13.1996 12.2979 12.782 12.3679L4.85221 13.6895C4.67184 13.7195 4.50314 13.7984 4.36446 13.9176C4.22579 14.0368 4.12245 14.1917 4.06568 14.3655L2.0581 20.5218C1.74808 21.4718 2.73762 22.3243 3.63116 21.8778L21.3789 13.0065C22.2084 12.5914 22.2084 11.4079 21.3789 10.9934L3.63116 2.12199Z"
                                    fill="#27A95D"
                                  />
                                </svg>
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: { background: "#1C1C1C", color: "#FFFFFF" },
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") handleSendMessage();
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChatBox;
