import React, { useState, useEffect } from "react";
import { Loginstyle } from "./Style";
import { Box, Container, TextField, FormControl, Button } from "@mui/material";
import theme from "../../theme";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { loginRoute } from "../../utils/APIRoutes";

export default function Register() {
  const TickIcon = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1206 6.35237C18.5021 5.93156 19.1753 5.88039 19.6241 6.23808C20.073 6.59577 20.1276 7.22686 19.746 7.64766L10.6794 17.6476C10.2919 18.075 9.60523 18.1201 9.15801 17.7474L4.35802 13.7474C3.91772 13.3805 3.87806 12.7484 4.26944 12.3356C4.66082 11.9229 5.33503 11.8857 5.77533 12.2526L9.76023 15.5733L18.1206 6.35237Z"
        fill="#27A95D"
      />
    </svg>
  );

  const [showPassword, setShowPassword] = React.useState(false);
  const [values, setValues] = useState({ username: "", password: "" });

  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_CURRENT_USER)) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const validateForm = () => {
    const { username, password } = values;
    if (username === "") {
      toast.error("Email and Password is required.", toastOptions);
      return false;
    } else if (password === "") {
      toast.error("Email and Password is required.", toastOptions);
      return false;
    }
    return true;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { username, password } = values;
      const { data } = await axios.post(loginRoute, {
        username,
        password,
      });
      if (data.status === false) {
        toast.error(data.msg, toastOptions);
      }
      if (data.status === true) {
        localStorage.setItem(
          process.env.REACT_APP_CURRENT_USER,
          JSON.stringify(data.user)
        );

        navigate("/");
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const [filled, setFilled] = useState(false);
  return (
    <Loginstyle>
      <Container>
        <Box className=" login " textAlign="center" mb={3}>
          {" "}
          Register{" "}
        </Box>

        <Box
          fontFamily="Poppins-Bold"
          color={theme.palette.primary.light}
          mb={3}
        >
          Get started!
        </Box>
        <TextField
          size="large"
          className="emailid"
          id="standard-basic"
          placeholder="Email address"
          color="primary"
          variant="standard"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon sx={{ color: "white" }} />
              </InputAdornment>
            ),
            endAdornment: filled && (
              <InputAdornment position="end">
                <TickIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setFilled(!!e.target.value)}
        />
        <Box style={{ borderBottom: "2px solid #5B5B5B", paddingTop: "8px" }} />
        <Box my={3}>
          <FormControl fullWidth variant="standard" className="emailid">
            <Input
              fullWidth
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder="password"
              onChange={(e) => handleChange("password", e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "white" }} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "white" }} />
                    ) : (
                      <Visibility sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box
            style={{ borderBottom: "2px solid #5B5B5B", paddingTop: "8px" }}
          />
        </Box>
        <Box my={3}>
          <FormControl fullWidth variant="standard" className="emailid">
            <Input
              fullWidth
              id="standard-adornment-password"
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm password"
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "white" }} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? (
                      <VisibilityOff sx={{ color: "white" }} />
                    ) : (
                      <Visibility sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box
            style={{ borderBottom: "2px solid #5B5B5B", paddingTop: "8px" }}
          />
        </Box>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => handleRegister(e)}
          sx={{
            marginTop: "30px",
            height: "56px",
            fontSize: "15px",
            fontFamily: "Poppins-Medium",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "rgba(39, 169, 93, 0.8)", // Change the opacity here (0.8 for example)
            },
          }}
        >
          Register
        </Button>
        {/* <Box my={4} className="orsetting" ></Box> */}
        <Box
          my={3}
          fontSize="13px"
          color="primary.dark"
          textAlign="center"
          fontFamily="Poppins-Regular"
        >
          By registering, you agree to our{" "}
          <span className=" terms-text "> Terms & Condition </span>
          and <span className=" terms-text "> Privacy Policy</span>
        </Box>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          sx={{
            marginTop: "30px",

            height: "56px",
            fontSize: "15px",
            fontFamily: "Poppins-Medium",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "rgba(28, 28, 28, 0.8)", // Change the opacity here (0.8 for example)
            },
          }}
        >
          <ul className=" googlebtn ">
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#FFC107"
                />
                <path
                  d="M3.15295 7.3455L6.43845 9.755C7.32745 7.554 9.48045 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C8.15895 2 4.82795 4.1685 3.15295 7.3455Z"
                  fill="#FF3D00"
                />
                <path
                  d="M11.9999 22C14.5829 22 16.9299 21.0115 18.7044 19.404L15.6094 16.785C14.5717 17.5742 13.3036 18.001 11.9999 18C9.39891 18 7.19041 16.3415 6.35841 14.027L3.09741 16.5395C4.75241 19.778 8.11341 22 11.9999 22Z"
                  fill="#4CAF50"
                />
                <path
                  d="M21.8055 10.0415H21V9.99997H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                  fill="#1976D2"
                />
              </svg>
            </li>
            <li>
              <Box ml={2}>Login with Google</Box>
            </li>
          </ul>
        </Button>
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          sx={{
            marginTop: "30px",
            // marginBottom:"30px",
            height: "56px",
            fontSize: "15px",
            fontFamily: "Poppins-Medium",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "rgba(28, 28, 28, 0.8)", // Change the opacity here (0.8 for example)
            },
          }}
        >
          <ul className=" googlebtn ">
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 12.061C22 6.50442 17.5229 1.99991 12 1.99991C6.47715 1.99991 2 6.50442 2 12.061C2 17.0828 5.65686 21.2451 10.4375 21.9999V14.9693H7.89844V12.061H10.4375V9.84443C10.4375 7.32287 11.9304 5.93003 14.2146 5.93003C15.3087 5.93003 16.4531 6.12654 16.4531 6.12654V8.60251H15.1921C13.9499 8.60251 13.5625 9.37806 13.5625 10.1737V12.061H16.3359L15.8926 14.9693H13.5625V21.9999C18.3431 21.2451 22 17.0828 22 12.061Z"
                  fill="#2670D2"
                />
              </svg>
            </li>
            <li>
              <Box ml={2}>Login with Facebook</Box>
            </li>
          </ul>
        </Button>

        <Box mb={3} textAlign="center">
          <ul className=" account-reg ">
            <li>
              <Box
                fontSize="15px"
                fontFamily="Poppins-Regular"
                color="primary.dark"
              >
                Already have an account?
              </Box>
            </li>
            <li>
              <Link className=" registerlink " to="/">
                Login
              </Link>
            </li>
          </ul>
        </Box>
      </Container>
      <ToastContainer />
    </Loginstyle>
  );
}
