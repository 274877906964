import { styled } from "@mui/material/styles";

export const Loginstyle = styled("div")(({ theme }) => ({
  "& .login": {
    color: theme.palette.primary.light,
    marginTop: "27px",
    fontFamily: "Poppins-SemiBold",
  },

  "& .emailid": {
    " & input": {
      color: "white",
      fontFamily: "Poppins-Regular",
    },
    "& .MuiInputAdornment-root:after": {
      borderBottom: "none",
    },
  },
  "& .forgot-btn": {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
  },
  "& .orsetting": {
    border: " 1px solid",
    borderImagesource: "linear-gradient(90deg, #3C3C3C 0.32%, #1C1C1C 100%)",
    height: "1px",
    width: "100%",
  },
  "& .googlebtn": {
    display: "inline-flex",
    listStyle: "none",
  },

  "& .registerlink": {
    fontSize: "15px",
    fontFamily: "Poppins-Medium",
    color: theme.palette.primary.main,
    listStyle: "none",
    cursor: "pointer",
    marginLeft: "10px",
  },
  "& .account-reg": {
    display: "inline-flex",
  },
  "& .terms-text": {
    color: theme.palette.primary.main,
    fontFamily: "Poppins-Medium",
    cursor: "pointer",
  },
}));
