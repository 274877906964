import React from "react";
import { IconButton } from "@mui/material";
import {
  InventoryOutlined,
  AttachMoneyOutlined,
  SupervisorAccountOutlined,
  NotificationsActiveOutlined,
} from "@mui/icons-material";

const GroupItem = ({ name, onClick, selectedGroup }) => {
  const iconMap = {
    inventory: InventoryOutlined,
    money: AttachMoneyOutlined,
    workers: SupervisorAccountOutlined,
    alerts: NotificationsActiveOutlined,
  };

  const IconComponent = iconMap[name];
  const bgcolor = selectedGroup === name ? "#27A95D" : "#1C1C1C";

  const handleClick = () => {
    onClick(name);
  };

  return (
    <IconButton
      name={name}
      onClick={handleClick}
      sx={{
        p: 1.8,
        bgcolor: bgcolor,
        borderRadius: 0.2,
      }}
    >
      <IconComponent
        sx={{
          fontSize: 35,
          borderRadius: 0,
          bgcolor: bgcolor,
          fill: selectedGroup === name ? "white" : "#979797",
          strokeWidth: 0.1,
        }}
      />
    </IconButton>
  );
};

export default GroupItem;
