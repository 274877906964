import { Container, Grid, Box, Button } from "@mui/material";
import React from "react";
import Mobdrawer from "../Userprofile/Mobdrawer";

export default function Navbar({ selectedGroup }) {
  return (
    <div>
      <Container>
        <Grid container my={2}>
          <Grid item xs={1} alignContent="center">
            <Mobdrawer />
          </Grid>
          <Grid item xs={10}>
            <Box
              fontSize="16px"
              fontFamily="Poppins-Medium"
              color="white"
              textAlign="center"
            >
              {selectedGroup}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.75 21C15.75 21.1989 15.671 21.3897 15.5303 21.5303C15.3897 21.671 15.1989 21.75 15 21.75H8.99999C8.80108 21.75 8.61031 21.671 8.46966 21.5303C8.32901 21.3897 8.24999 21.1989 8.24999 21C8.24999 20.8011 8.32901 20.6103 8.46966 20.4697C8.61031 20.329 8.80108 20.25 8.99999 20.25H15C15.1989 20.25 15.3897 20.329 15.5303 20.4697C15.671 20.6103 15.75 20.8011 15.75 21ZM20.7975 18C20.6674 18.2291 20.4787 18.4194 20.2506 18.5512C20.0225 18.683 19.7634 18.7517 19.5 18.75H4.49999C4.23631 18.7496 3.97737 18.6798 3.74929 18.5475C3.52121 18.4151 3.33204 18.225 3.20085 17.9963C3.06966 17.7676 3.00108 17.5083 3.00202 17.2446C3.00297 16.9809 3.07341 16.7222 3.20624 16.4944C3.72655 15.5981 4.49999 13.0631 4.49999 9.75C4.49999 7.76088 5.29017 5.85322 6.69669 4.4467C8.10321 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75C19.5 13.0622 20.2744 15.5981 20.7947 16.4944C20.9288 16.7225 20.9998 16.9822 21.0003 17.2468C21.0008 17.5114 20.9308 17.7714 20.7975 18Z"
                  fill="#F9F9F9"
                />
                <circle cx="17" cy="6" r="5" fill="#CE254F" />
              </svg>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
