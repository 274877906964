import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SetAvatar from "./components/SetAvatar";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import ChatBox from "./pages/Chatbox/Chatbox";
import socket from "./utils/AppSocket";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/setAvatar" element={<SetAvatar />} />
        <Route path="/" element={<ChatBox socket={socket} />} />
      </Routes>
    </BrowserRouter>
  );
}
